import styles from './TextComponent.module.scss';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import { Kicker, KickerProps } from 'ui/components/1-atoms/Kicker/Kicker';
import { RichText } from 'ui/components/1-atoms/RichText/RichText';
import ColorOptions from 'helpers/types';
import classNames from 'classnames';
import { useState } from 'react';
import { Button } from 'ui/components/1-atoms/Action/Button/Button';

export interface TextComponentProps {
	bgColor?: ColorOptions;
	heading?: string;
	kicker?: KickerProps;
	text?: string;
	readMoreText?: string;
	readMoreButtonText?: string;
}

export const TextComponent: React.FC<TextComponentProps> = ({ heading, kicker, text, readMoreText, readMoreButtonText, bgColor = 'white' }) => {


	return (
		<div className={classNames(styles.TextComponent, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<TopLevelGrid wrap={true}>
					{kicker && (
						<TopLevelGridCell desktopWidth="7col" mobileWidth="100">
							<Kicker {...kicker} className={styles.TextComponent_kicker} />
						</TopLevelGridCell>
					)}
					<TopLevelGridCell desktopWidth="17col" desktopOffset={kicker ? null : '7col'} mobileWidth="100">
						<Heading headingLevel="h2" style="xl" className={styles.TextComponent_heading}>
							{heading}
						</Heading>

						<RichText readMoreText={readMoreText} readMoreButtonText={readMoreButtonText} content={text} className={styles.TextComponent_content} />

					</TopLevelGridCell>
				</TopLevelGrid>
			</Container>
		</div>
	);
};
