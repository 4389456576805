import { TextComponent } from 'ui/components/3-organisms/TextComponent/TextComponent';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const TextComponentFeature: React.FC<Content.TextComponent> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const readMoreButtonText = dictionary.getValue('Article.ReadMore');

	return (
		<TextComponent
			bgColor={content?.properties?.color}
			heading={content?.properties?.heading}
			kicker={{
				firstWord: content?.properties?.kickerFirstWord,
				secondWord: content?.properties?.kickerSecondWord,
			}}
			text={content?.properties?.text}
			readMoreText={content?.properties?.readMoreText}
			readMoreButtonText={readMoreButtonText}
		/>
	);
};
